<!--<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">

	<ul class="nav navbar-nav flex-row">
		<li class="nav-item">
			<a class="navbar-brand" (click)="redirectToHome()">
       <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36"/>
        </span>
				<h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
			</a>
		</li>
	</ul>

</div>-->

<div class="row container-fluid">

	<div class="col-7 col-md-5 col-xl-4">
		<div class="navbar-container d-flex content">
			<div class="bookmark-wrapper d-flex align-items-center">
				<!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
				<ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
					<li class="nav-item">
						<a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
							<span [data-feather]="'menu'" [class]="'ficon'"></span>
						</a>
					</li>
				</ul>
				<!--/ Menu Toggler -->
				<li class="nav-item d-block" *ngIf="coreConfig.layout.type==='horizontal'">
			<span class="font-weight-bolder" style="padding-left: 8px;">
				<a class="navbar-brand" (click)="redirectToHome()"><img style="max-width: 75%;" src="assets/images/logo/logo-previdir-sm.png" width="262" alt="Logo Portale"/></a></span>
				</li>
			</div>

		</div>
	</div>

	<div class="col-5 col-md-7 col-xl-auto pe-0">
		<div class="navbar-container d-flex content pe-0">

			<button
				class="btn btn-primary btn-icon me-1 d-none d-xl-inline"
				(click)="openPage()">
				<fa-icon [icon]="'file-pen'"></fa-icon> Richiedi Preventivo
			</button>
			<button
				class="btn btn-warning btn-icon me-1 me-sm-2 d-none d-xl-inline"
				(click)="openModalSupport()">
				<fa-icon [icon]="'triangle-exclamation'"></fa-icon> Invia Segnalazione
			</button>

			<ul class="nav navbar-nav align-items-center ml-auto" style="margin-left:auto;">


				<!--<li class="nav-item d-none d-lg-block">
					<a class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
						<span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
					</a>
				</li>-->

				<!-- <app-navbar-search></app-navbar-search>

				<app-navbar-notification></app-navbar-notification> -->

				<!--		<div class="me-1">-->
				<!--			<button class='btn btn-warning btn-icon' (click)="openModalSupport()">-->
				<!--				<fa-icon [icon]="'triangle-exclamation'"></fa-icon>-->
				<!--			</button>-->
				<!--		</div>-->

				<li ngbDropdown class="nav-item dropdown-user">
					<a
						class='nav-link dropdown-toggle dropdown-user-link'
						id='dropdown-user'
						ngbDropdownToggle
						id='navbarUserDropdown'
						aria-haspopup='true'
						aria-expanded='false'
					>
						<ng-container *ngIf="this._authenticationService?.userData?.referente; else adminProfile">
							<div class="user-nav d-lg-flex d-none">
            <span class="user-name font-weight-bolder"
						>{{this._authenticationService?.userData?.referente}}</span
						><span class="user-status text-wrap">{{azienda?.ragsoc}}</span>
							</div>
							<span class="avatar">
						<i class="feather icon-user"></i>
					</span>
						</ng-container>

						<ng-template #adminProfile>
							<div class="user-nav d-sm-flex d-none">
            <span class="user-name font-weight-bolder"
						>{{this._authenticationService?.userData?.name}} {{this._authenticationService?.userData?.surname}}</span
						><span class="user-status">{{azienda?.ragsoc}}</span>
							</div>
							<span class="avatar">
						<i class="feather icon-user"></i>
					</span>
						</ng-template>
					</a>
					<div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
						<!-- <a ngbDropdownItem [routerLink]="['/pages/profile']">
							<span [data-feather]="'user'" [class]="'mr-50'"></span> Profile
						</a> -->
						<!--<a ngbDropdownItem [routerLink]="['/apps/email']">
								<span [data-feather]="'mail'" [class]="'mr-50'"></span> Inbox
							</a>
							<a ngbDropdownItem [routerLink]="['/apps/todo']">
								<span [data-feather]="'check-square'" [class]="'mr-50'"></span> Task
								</a>
								<a ngbDropdownItem [routerLink]="['/apps/chat']">
									<span [data-feather]="'message-square'" [class]="'mr-50'"></span> Chats
								</a>
						<div class="dropdown-divider"></div>
						<a ngbDropdownItem [routerLink]="['/pages/account-settings']"
						><span [data-feather]="'settings'" [class]="'mr-50'"></span> Settings</a
						><a ngbDropdownItem [routerLink]="['/pages/pricing']"
					><span [data-feather]="'credit-card'" [class]="'mr-50'"></span> Pricing</a
					><a ngbDropdownItem [routerLink]="['/pages/faq']"
					><span [data-feather]="'help-circle'" [class]="'mr-50'"></span> FAQ</a
					>-->
						<a ngbDropdownItem *ngIf="this._authenticationService?.userData?.referente" (click)="gotoProfilo()"><span [data-feather]="'user'" [class]="'mr-50'"></span> Profilo</a>
						<a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span> Logout</a>
					</div>
				</li>
			</ul>
		</div>
	</div>
</div>

<!--<div class="navbar-container d-flex content customWidthLogo">-->
<!--	<div class="bookmark-wrapper d-flex align-items-center">-->
<!--		&lt;!&ndash; Menu Toggler | Menu icon will be hidden in case of layout without menu &ndash;&gt;-->
<!--		<ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">-->
<!--			<li class="nav-item">-->
<!--				<a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">-->
<!--					<span [data-feather]="'menu'" [class]="'ficon'"></span>-->
<!--				</a>-->
<!--			</li>-->
<!--		</ul>-->
<!--		&lt;!&ndash;/ Menu Toggler &ndash;&gt;-->
<!--		<li class="nav-item d-block" *ngIf="coreConfig.layout.type==='horizontal'">-->
<!--			<span class="font-weight-bolder" style="padding-left: 8px;">-->
<!--				<a class="navbar-brand" (click)="redirectToHome()"><img style="max-width: 75%;" src="assets/images/logo/logo-previdir-sm.png" width="262" alt="Logo Portale"/></a></span>-->
<!--		</li>-->
<!--	</div>-->

<!--</div>-->

<!--<div class="navbar-container d-flex content customWidthUtente">-->

<!--	<ul class="nav navbar-nav align-items-center ml-auto" style="margin-left:auto;">-->


<!--		&lt;!&ndash;<li class="nav-item d-none d-lg-block">-->
<!--			<a class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">-->
<!--				<span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>-->
<!--			</a>-->
<!--		</li>&ndash;&gt;-->

<!--		&lt;!&ndash; <app-navbar-search></app-navbar-search>-->

<!--		<app-navbar-notification></app-navbar-notification> &ndash;&gt;-->

<!--&lt;!&ndash;		<div class="me-1">&ndash;&gt;-->
<!--&lt;!&ndash;			<button class='btn btn-warning btn-icon' (click)="openModalSupport()">&ndash;&gt;-->
<!--&lt;!&ndash;				<fa-icon [icon]="'triangle-exclamation'"></fa-icon>&ndash;&gt;-->
<!--&lt;!&ndash;			</button>&ndash;&gt;-->
<!--&lt;!&ndash;		</div>&ndash;&gt;-->

<!--		<li ngbDropdown class="nav-item dropdown-user">-->
<!--			<a-->
<!--				class='nav-link dropdown-toggle dropdown-user-link'-->
<!--				id='dropdown-user'-->
<!--				ngbDropdownToggle-->
<!--				id='navbarUserDropdown'-->
<!--				aria-haspopup='true'-->
<!--				aria-expanded='false'-->
<!--			>-->
<!--				<ng-container *ngIf="this._authenticationService?.userData?.referente; else adminProfile">-->
<!--					<div class="user-nav d-lg-flex d-none">-->
<!--            <span class="user-name font-weight-bolder"-->
<!--			>{{this._authenticationService?.userData?.referente}}</span-->
<!--			><span class="user-status">{{azienda?.ragsoc}}</span>-->
<!--					</div>-->
<!--					<span class="avatar">-->
<!--						<i class="feather icon-user"></i>-->
<!--					</span>-->
<!--				</ng-container>-->

<!--				<ng-template #adminProfile>-->
<!--					<div class="user-nav d-sm-flex d-none">-->
<!--            <span class="user-name font-weight-bolder"-->
<!--						>{{this._authenticationService?.userData?.name}} {{this._authenticationService?.userData?.surname}}</span-->
<!--						><span class="user-status">{{azienda?.ragsoc}}</span>-->
<!--					</div>-->
<!--					<span class="avatar">-->
<!--						<i class="feather icon-user"></i>-->
<!--					</span>-->
<!--				</ng-template>-->
<!--			</a>-->
<!--			<div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">-->
<!--				&lt;!&ndash; <a ngbDropdownItem [routerLink]="['/pages/profile']">-->
<!--					<span [data-feather]="'user'" [class]="'mr-50'"></span> Profile-->
<!--				</a> &ndash;&gt;-->
<!--				&lt;!&ndash;<a ngbDropdownItem [routerLink]="['/apps/email']">-->
<!--						<span [data-feather]="'mail'" [class]="'mr-50'"></span> Inbox-->
<!--					</a>-->
<!--					<a ngbDropdownItem [routerLink]="['/apps/todo']">-->
<!--						<span [data-feather]="'check-square'" [class]="'mr-50'"></span> Task-->
<!--						</a>-->
<!--						<a ngbDropdownItem [routerLink]="['/apps/chat']">-->
<!--							<span [data-feather]="'message-square'" [class]="'mr-50'"></span> Chats-->
<!--						</a>-->
<!--				<div class="dropdown-divider"></div>-->
<!--				<a ngbDropdownItem [routerLink]="['/pages/account-settings']"-->
<!--				><span [data-feather]="'settings'" [class]="'mr-50'"></span> Settings</a-->
<!--				><a ngbDropdownItem [routerLink]="['/pages/pricing']"-->
<!--			><span [data-feather]="'credit-card'" [class]="'mr-50'"></span> Pricing</a-->
<!--			><a ngbDropdownItem [routerLink]="['/pages/faq']"-->
<!--			><span [data-feather]="'help-circle'" [class]="'mr-50'"></span> FAQ</a-->
<!--			>&ndash;&gt;-->
<!--				<a ngbDropdownItem *ngIf="this._authenticationService?.userData?.referente" (click)="gotoProfilo()"><span [data-feather]="'user'" [class]="'mr-50'"></span> Profilo</a>-->
<!--				<a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span> Logout</a>-->
<!--			</div>-->
<!--		</li>-->
<!--	</ul>-->
<!--</div>-->
