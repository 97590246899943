import { CoreMenu } from '@core/types';

export const menu: CoreMenu[] = [
	{
		id: 'home',
		title: 'Home',
		translate: 'MENU.HOME',
		type: 'item',
		icon: 'home',
		url: 'home',
	},
	{
		id: 'azienda',
		title: 'Azienda',
		type: 'item',
		icon: 'building',
		url: 'azienda',
	},
	{
		id: 'iscritti',
		title: 'Iscritti',
		type: 'item',
		icon: 'users',
		url: 'iscritti',
	},
	{
		id: 'programmi',
		title: 'Programmi',
		type: 'item',
		icon: 'file-invoice',
		url: 'programmi',
	},
	// {
	// 	id: 'admin',
	// 	type: 'collapsible',
	// 	title: 'Amministrazione',
	// 	icon: 'euro',
	// 	translate: 'MENU.ADMIN',
	// 	children: [
			// {
			// 	id: 'invoices',
			// 	title: 'Fatture Inviate',
			// 	translate: 'MENU.SENT_INVOICES',
			// 	type: 'item',
			// 	icon: 'file-invoice',
			// 	url: 'invoices/sent',
			// },
			// {
			// 	id: 'clients',
			// 	title: 'Clienti',
			// 	translate: 'MENU.CLIENTS',
			// 	type: 'item',
			// 	icon: 'user',
			// 	url: 'clients',
			// },
			// ],
			// },
	{
		id: 'richieste-contributi',
		title: 'Richieste Contributi',
		type: 'item',
		icon: 'file-invoice-dollar',
		url: 'richieste-contributi',
		classes: 'btn btn-outline-primary rounded'
	},
	{
		id: 'richiedi-preventivo',
		title: 'Richiedi Preventivo',
		type: 'item',
		icon: 'file-pen',
		url: 'https://preventivi.previdir.it/preventivo.asp',
		openInNewTab: true,
		externalUrl: true,
		classes: 'btn btn-primary text-warning rounded d-xl-none mt-5 mt-xl-0',
	},
	{
		id: 'invia-segnalazioni',
		title: 'Invia Segnalazione',
		type: 'item',
		icon: 'triangle-exclamation',
		classes: 'btn btn-warning rounded d-xl-none'
	},

	// {
	// 	id: 'form',
	// 	title: 'Form wizard',
	// 	type: 'item',
	// 	icon: 'folder',
	// 	url: 'form',
	// },
];
