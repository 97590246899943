import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	template: ` <button *ngIf="diplayText" [class]="class" (click)="btnClickedHandler($event)">{{ diplayText }}</button> `,
})
export class BtnCellRendererComponent implements ICellRendererAngularComp {
	private params: any;
	diplayText: string;
	class: string;

	constructor(private _router: Router, private _route: ActivatedRoute) {}

	agInit(params: any): void {
		this.params = params;
		if (this.params.label) {
			this.diplayText = this.params.label;
		} else {
			this.diplayText = this.params.value;
		}

		if (this.params.class) {
			this.class = this.params.class;
		} else {
			this.class = 'btn btn-primary stile-btn';
		}

		// this.params.url = this.params.url.replace('{field}', this.params.data[this.params.field]);

		let indexStart = 0;
		const paramsName = [];
		let tempUrl = this.params.url;
		if (tempUrl) {
			while (1) {
				indexStart = tempUrl.indexOf('{') + 1;

				if (indexStart === 0) {
					break;
				}

				let paramTemp = tempUrl.substr(indexStart);
				tempUrl = paramTemp;

				paramTemp = paramTemp.substr(0, paramTemp.indexOf('}'));
				paramsName.push(paramTemp);
			}

			for (const paramName of paramsName) {
				const paramNameSplit = paramName.split('.');

				let paramValue = undefined;

				let first = true;
				for (const paramNamePath of paramNameSplit) {
					if (first) {
						paramValue = this.params.data[paramNamePath];
					} else {
						paramValue = paramValue[paramNamePath];
					}
					first = false;
				}

				this.params.url = this.params.url.replace(`{${paramName}}`, paramValue);
			}
		}

	}

	btnClickedHandler($event) {
		if (this.params.url) {
			if (this.params.url.startsWith('/')) {
				this._router.navigate([this.params.url]);
			} else {
				this._router.navigate([this.params.url], { relativeTo: this._route });
			}
		} else {
			this.params.onClick(this.params.data);
		}

	}

	// gets called whenever the cell refreshes
	refresh(params: ICellRendererParams) {
		return true;
		// set value into cell again
	}
}
